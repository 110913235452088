/*import React from 'react'
import ReactDOM from 'react-dom'
import HelloWorld from './components/HelloWorld';

ReactDOM.render(
<React.StrictMode>

<HelloWorld />
</React.StrictMode>,
document.getElementById("root")
);
*/

// Our modules / classes
//import TestComponent from "./components/TestComponent"
import MobileMenu from "./components/MobileMenu"
import Search  from "./components/Search"
// Instantiate a new object using our modules/classes
//const testcomponent = new TestComponent()

const mobilemenu = new MobileMenu();
const search = new Search();
